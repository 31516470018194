import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";

export class EstimateLinkProvider {
  public static readonly BASE_URL = 'estimate';

  static getEstimateRouterLink(estimateID: EstimateID): string[] {
    return [
      ...EstimateLinkProvider.getBaseUrl(),
      estimateID.getValue()
    ]
  }

  static getEstimatesListRouterLink(jobID: JobID): string[] {
    return [
      ...EstimateLinkProvider.getBaseUrl(),
      'list',
      jobID.toString()
    ];
  }

  static getCopyEstimateRouterLink(estimateID: EstimateID): string[] {
    return [
      ...EstimateLinkProvider.getBaseUrl(),
      'copy',
      estimateID.getValue()
    ];
  }

  private static getBaseUrl(): string[] {
    return ['/', EstimateLinkProvider.BASE_URL];
  }
}
