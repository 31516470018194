import "reflect-metadata";
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableSentry} from "@modules/sentry/enable-sentry";

import {AppModule} from './_app/app.module';
import {environment} from './environments/environment';
import {EnvironmentType} from "./environments/environment-type";
import 'hammerjs';


if (environment.environment === EnvironmentType.production) {
  enableProdMode();

  if (window.location.hostname !== 'localhost') {
    enableSentry();
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
