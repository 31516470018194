import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";
import {Crews} from "@modules/gm-inputs/Domain/Crew/crews";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {GmInputsID} from "@modules/gm-inputs/Domain/GmInputs/VO/gm-inputs-id";
import {OtherRates} from "../Other/other-rates";

export class GmInputs {

  constructor(
    public readonly id: GmInputsID,
    public readonly businessUnitID: BusinessUnitID,
    public readonly pinCode: string,
    private crews: Crews,
    private otherRates: OtherRates
  ) {
  }

  getCrews(): Crews {
    return this.crews;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getOveragePercentage(): number {
    return this.otherRates.overagePercentage.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getBenefitsRatePercentage(): number {
    return this.otherRates.benefitsRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getSalesTaxRatePercentage(): number {
    return this.otherRates.salesTaxRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getRoyaltyRatePercentage(): number {
    return this.otherRates.royaltyRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getCommissionRatePercentage(): number {
    return this.otherRates.commissionRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getCCPercent(): number {
    return this.otherRates.ccPercent.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */

  setOtherRates(otherRates: OtherRates): GmInputs {
    return new GmInputs(this.id, this.businessUnitID, this.pinCode, this.crews, otherRates);
  }

  setCrews(crews: Crews): GmInputs {
    return new GmInputs(this.id, this.businessUnitID, this.pinCode, crews, this.otherRates);
  }

  getTotallyCrewsHourlyRate(): number {
    return this.crews.getTotalHourlyRate();
  }

  getCrewFullyLoadedRate(crew: Crew): number {
    return crew.rate * (1 + this.getBenefitsRatePercentage());
  }

  getTotallyLoadedCrewsHourlyRate(): number {
    return this.crews.getTotalHourlyRate() * (1 + this.getBenefitsRatePercentage());
  }

  getLaborExpense(projHours: number, labourCrew: CrewID[]): number {
    try {
      const techsUsed = this.crews.getCrewsUsed(labourCrew);
      const totalHourlyRate = techsUsed.reduce((acc, crew) => {
        return acc + crew.rate;
      }, 0);
      return totalHourlyRate * projHours;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return 0;
    }
  }

  getLaborFullyLoadedExpense(projHours: number, crewIDs: CrewID[]): number {
    const crewSize = this.crews.getCrewsUsed(crewIDs).length;
    return this.getLaborExpense(projHours, crewIDs) * (1 + this.getBenefitsRatePercentage()) * crewSize;
  }

  checkPin(pin: string) {
    return this.pinCode === pin;
  }
}
