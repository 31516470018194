import {Estimate} from "../estimate";

export class Estimates {
  private estimates: Estimate[] = [];

  constructor(private jobID: number) {
  }

  addEstimate(estimate: Estimate): void {
    if (estimate.jobID !== this.jobID) {
      throw new Error("Estimate jobID must match.");
    }

    this.estimates.push(estimate);
  }

  getEstimates(): Estimate[] {
    return this.estimates;
  }

  updateEstimate(updatedEstimate: Estimate): Estimates {
    const index = this.estimates.findIndex(estimate => estimate.id.equals(updatedEstimate.id));
    if (index === -1) {
      throw new Error(`Estimate with ID ${updatedEstimate.id} not found`);
    }

    const estimates = [...this.estimates];
    estimates[index] = updatedEstimate;

    this.estimates = estimates;
    return this;
  }

  removeEstimate(estimate: Estimate): Estimates {
    const index = this.estimates.findIndex(e => e.id === estimate.id);
    if (index === -1) {
      throw new Error(`Estimate with ID ${estimate.id} not found`);
    }

    const estimates = [...this.estimates];
    estimates.splice(index, 1);

    this.estimates = estimates;
    return this;
  }

  filterEstimates(searchTerm: string): Estimate[] {
    return this.estimates.filter(estimate => estimate.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }
}
