import {Injectable} from "@angular/core";
import {ESTIMATE_SUMMARY_TOTAL_TAB_ID} from "@modules/estimate-summary/Domain/EstimateSummaryTab/estimate-summary-tab";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";

@Injectable({
  providedIn: 'root'
})
export class EstimateSummaryLinkProvider {

  public static BASE_URL = 'estimate-summary';

  public static getEstimateSummaryRouterLink(estimateID: EstimateID): string[] {
    return [
      ...EstimateSummaryLinkProvider.getBaseRouterLink(),
      estimateID.toString(),
      ESTIMATE_SUMMARY_TOTAL_TAB_ID.getValue()
    ];
  }

  private static getBaseRouterLink(): string[] {
    return ['/', EstimateSummaryLinkProvider.BASE_URL];
  }
}
