import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {EstimateOptions} from "@modules/estimate/Domain/EstimateOption/estimate-options";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import DynamicsEstimateFactory
  from "@modules/estimate/Infrastructure/Repository/DynamicsEstimateRepository/Mapper/dynamics-estimate-factory";
import {
  DynamicsEstimate
} from "@modules/estimate/Infrastructure/Repository/DynamicsEstimateRepository/Model/dynamics-estimate";
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsUpdateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-update-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {CreateRequest, DeleteRequest, RetrieveMultipleRequest, RetrieveRequest, UpdateRequest} from "dynamics-web-api";
import {Estimates} from "../../../Domain/EstimateOption/Aggregate/estimates";
import {Estimate} from "../../../Domain/EstimateOption/estimate";
import {EstimateRepository} from "../../../Domain/EstimateOption/Repository/estimate-repository";

@Injectable()
export class DynamicsEstimateRepository implements EstimateRepository {
  private readonly tableName = "cr9b4_ifoamestimates";
  private readonly jobIDFieldName = "cr9b4_servicetitanjobid";


  constructor(
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsUpdateCommand: DynamicsUpdateCommand,
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsDeleteCommand: DynamicsDeleteRecordCommand,
    private readonly dynamicsEstimatesFactory: DynamicsEstimateFactory,
  ) {
  }

  public getTableName(): string {
    return this.tableName;
  }

  async getEstimatesByJobID(jobID: string): Promise<Estimates> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `${this.jobIDFieldName} eq ${jobID}`,
    };
    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsEstimate>(request);
    return this.dynamicsEstimatesFactory.createEstimates(response);
  }

  async updateEstimate(estimate: Partial<EstimateOptions>): Promise<Estimate> {
    if (!estimate.id) throw new Error("id is required");

    const request: UpdateRequest = {
      collection: this.tableName,
      key: estimate.id.getValue(),
      data: this.dynamicsEstimatesFactory.getDynamicFields(estimate),
      returnRepresentation: true,
    };

    const response = await this.dynamicsUpdateCommand.execute<DynamicsEstimate>(request);
    return this.dynamicsEstimatesFactory.createEstimate(response);
  }

  deleteEstimate(estimateID: EstimateID): Promise<void> {
    const request: DeleteRequest = {
      collection: this.tableName,
      key: estimateID.getValue(),
    }
    return this.dynamicsDeleteCommand.execute(request);
  }

  async getEstimateByID(estimateID: EstimateID): Promise<Estimate> {
    const request: RetrieveRequest = {
      collection: this.tableName,
      key: estimateID.toString()
    }

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsEstimate>(request);
    return this.dynamicsEstimatesFactory.createEstimate(response);
  }

  async createEstimate(
    jobID: JobID,
    businessUnitID: BusinessUnitID,
    name: string,
  ): Promise<Estimate> {
    const createRequest: CreateRequest = {
      collection: this.tableName,
      data: this.dynamicsEstimatesFactory.createDynamicsEstimateFields(jobID, businessUnitID, name),
      returnRepresentation: true,
    };

    const createResponse = await this.dynamicsCreateCommand.execute<DynamicsEstimate>(createRequest);
    return this.dynamicsEstimatesFactory.createEstimate(createResponse);
  }

  async getEstimatesJobIDs(): Promise<JobID[]> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      select: [this.jobIDFieldName],
      filter: `${this.jobIDFieldName} ne null`,
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsEstimate>(request);
    return response.value.map((estimate) => new JobID(estimate[this.jobIDFieldName]));
  }
}
