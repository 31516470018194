import {Injectable} from "@angular/core";
import {MissingRequiredFieldsException} from "@modules/_shared/Service/Validator/missing-required-fields.exception";
import {RequiredFieldsValidator} from "@modules/_shared/Service/Validator/required-fields-validator.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {
  DynamicsEstimate
} from "@modules/estimate/Infrastructure/Repository/DynamicsEstimateRepository/Model/dynamics-estimate";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {RetrieveMultipleResponse} from "dynamics-web-api";
import {EstimateFactory} from "../../../../Application/Factory/estimate-factory";
import {EstimatesFactory} from "../../../../Application/Factory/estimates-factory";
import {Estimates} from "../../../../Domain/EstimateOption/Aggregate/estimates";
import {Estimate} from "../../../../Domain/EstimateOption/estimate";
import {EstimateOptions} from "../../../../Domain/EstimateOption/estimate-options";

@Injectable({
  providedIn: 'root'
})
export default class DynamicsEstimateFactory {
  private readonly requiredFields: (keyof DynamicsEstimate)[] = ["cr9b4_servicetitanjobid", "cr9b4_name", "cr9b4_ifoamestimateid"];

  constructor(
    private readonly estimatesFactory: EstimatesFactory,
    private readonly estimateFactory: EstimateFactory,
    private readonly requiredValidator: RequiredFieldsValidator
  ) {
  }

  createEstimates(response: RetrieveMultipleResponse<DynamicsEstimate>): Estimates {
    const jobID = this.getJobID(response);

    const estimateOptions: EstimateOptions[] = [];
    for (const estimate of response.value) {
      try {
        this.requiredValidator.validate(estimate, this.requiredFields);
        const estimateOption = this.createEstimateOption(estimate);
        estimateOptions.push(estimateOption);
      } catch (e: MissingRequiredFieldsException | unknown) {
        if (e instanceof MissingRequiredFieldsException) {
          console.error(`Estimate with id ${estimate.cr9b4_ifoamestimateid}: ${e.message}`);
        }
      }
    }

    return this.estimatesFactory.createEstimates(jobID, estimateOptions);
  }

  createEstimate(response: DynamicsEstimate): Estimate {
    this.requiredValidator.validate(response, this.requiredFields);
    const estimateOption = this.createEstimateOption(response);
    return this.estimateFactory.create(estimateOption);
  }

  createDynamicsEstimateFields(
    jobID: JobID,
    businessUnitID: BusinessUnitID,
    name: string,
  ): Partial<DynamicsEstimate> {
    // Create a relationship between the calculation and the business unit
    const businessUnitKey = "owningbusinessunit@odata.bind";
    const businessUnitValue = `/businessunits(${businessUnitID})`;

    return {
      cr9b4_name: name,
      cr9b4_servicetitanjobid: jobID.getValue(),
      [businessUnitKey]: businessUnitValue,
    };
  }

  getDynamicFields(estimate: Partial<EstimateOptions>): Partial<DynamicsEstimate> {
    const dynamicsEstimate: Partial<DynamicsEstimate> = {};

    if (estimate.name) {
      dynamicsEstimate.cr9b4_name = estimate.name;
    }

    if (estimate.estimateDollar) {
      dynamicsEstimate.cr9b4_estimatedollar = estimate.estimateDollar;
    }

    if (estimate.discount) {
      dynamicsEstimate.cr9b4_discount = estimate.discount;
    }

    return dynamicsEstimate;
  }

  private getJobID(response: RetrieveMultipleResponse<DynamicsEstimate>) {
    const jobIDs = response.value.map(v => v.cr9b4_servicetitanjobid);
    const uniqueJobIDs = [...new Set(jobIDs)];

    if (uniqueJobIDs.length > 1) {
      throw new Error("Estimates must belong to the same Job, but they belong to different job-list: " + uniqueJobIDs.join(", "));
    }

    return uniqueJobIDs[0];
  }

  private createEstimateOption(estimate: DynamicsEstimate): EstimateOptions {
    return {
      id: EstimateID.fromString(estimate.cr9b4_ifoamestimateid),
      createdBy: estimate._createdby_value,
      createdOn: new Date(estimate.createdon),
      name: estimate.cr9b4_name,
      jobID: Number(estimate.cr9b4_servicetitanjobid),
      estimateDollar: estimate.cr9b4_estimatedollar,
      discount: estimate.cr9b4_discount,
    };
  }
}
