import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {EstimateID as ServiceTitanEstimateID} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/VO/estimate-id";

export class ServiceTitanEstimateLinkProvider {
  public static readonly BASE_URL = 'service-titan-estimate';

  public static getBaseUrl(): string[] {
    return ['/', ServiceTitanEstimateLinkProvider.BASE_URL];
  }

  public static getPublishEstimateLink(id: EstimateID): string[] {
    return [...ServiceTitanEstimateLinkProvider.getBaseUrl(), 'publish-estimate', id.toString()];
  }

  public static getEstimateLink(
    localID: EstimateID,
    serviceTitanID: ServiceTitanEstimateID
  ): string[] {
    return [...ServiceTitanEstimateLinkProvider.getBaseUrl(), 'estimate', serviceTitanID.toString(), localID.toString()];
  }

}
