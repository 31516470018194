import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  CalculationAreaFactory
} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {
  BattingMaterialSourceEnum
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-material-source-enum";
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {
  BattingArea
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area";
import {
  BattingAreaOptions
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area-options";
import {ConstructionType} from "@modules/calculation-impl/batting/construction-type/construction-type";

@Injectable({
  providedIn: 'root'
})
export class BattingAreaFactory extends CalculationAreaFactory<BattingArea, BattingAreaOptions> {
  private readonly defaults: BattingAreaOptions = {
    id: '',
    name: 'Batting',
    sqft: 0,
    constructionName: '',
    onCenter: 0,
    facingName: '',
    rValue: '',
    productID: '',
    projHours: 0,
    laborCrew: [],
    miscellaneous: 0,
    battingServiceName: BattingServiceEnum.BattInsulation,
    materialSource: BattingMaterialSourceEnum.bagsCost,
    constructionType: ConstructionType.WoodFrame,
    filterType: '',
    depth: 0,
    businessUnitID: new BusinessUnitID(''),
  };

  execute(data: Partial<BattingAreaOptions> = {}): BattingArea {
    const validData = this.removeInvalidFields(data);
    const mergedData: BattingAreaOptions = {...this.defaults, ...validData};
    return new BattingArea(mergedData);
  }
}
