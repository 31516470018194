import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {Estimates} from "../Aggregate/estimates";
import {Estimate} from "../estimate";
import {EstimateOptions} from "../estimate-options";

export abstract class EstimateRepository {
  /**
   * @param jobID
   * @throws EstimatesNotFoundException
   */
  abstract getEstimatesByJobID(jobID: string): Promise<Estimates>;

  abstract updateEstimate(estimate: Partial<EstimateOptions>): Promise<Estimate>;

  abstract createEstimate(
    jobID: JobID,
    businessUnitID: BusinessUnitID,
    name: string,
  ): Promise<Estimate>;

  abstract deleteEstimate(estimateID: EstimateID): Promise<void>;

  abstract getEstimateByID(estimateID: EstimateID): Promise<Estimate>;

  abstract getEstimatesJobIDs(): Promise<JobID[]>;
}
